exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notes-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/notes/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-notes-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-notes-tsx": () => import("./../../../src/pages/notes.tsx" /* webpackChunkName: "component---src-pages-notes-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

